import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import Section from "../components/section";
import Layout from "../components/layout";
import CTADemo from "../components/sections/cta-demo";
import Logo from "../assets/coeus-by-dfs-logo-light.svg"
import SEO from "../components/seo";

const AboutUs = () => {
    return (
        <Layout>
            <Section id="hero" className="relative bg-slate-900/70 after:bg-slate-900/60 after:block after:h-full after:absolute after:right-0 after:left-0 after:top-0 after:content-['']">
                <div className="z-0 absolute left-0 bottom-0 right-0 top-0 overflow-hidden">
                    <StaticImage alt="team" src="../assets/about-us-bg.jpeg" className="w-full h-full" imgClassName="object-cover w-full h-full" layout="fullWidth" />
                </div>

                <div className="z-10 relative flex h-full items-center justify-center text-center px-8 py-36 sm:py-48 md:py-60 xl:px-0 container max-w-7xl mx-auto">
                    <div className="max-w-sm sm:max-w-md md:max-w-xl text-white">
                        <div className="space-y-2">
                            <h1 className="text-4xl sm:text-5xl md:text-6xl font-bold">
                                Who We Are
                            </h1>
                            <p className="text-lg md:text-xl font-normal">
                                Learn about what Coeus is all about and who makes up our team
                            </p>
                        </div>
                    </div>
                </div>
            </Section>

            <Section>
                <div className="space-y-6">
                    <h1 className="text-4xl bold text-center">About Us</h1>
                    <div className="flex justify-center space-x-10">
                        <p className="text-lg w-6/12">The Coeus Division of Data Fusion Specialists, LLC is a dedicated team of IT professionals who bring many years of experience to the table for our clients.</p>
                    </div>
                    <div className="flex justify-center space-x-10">
                        <Logo className="w-1/6" />
                    </div>
                </div>
            </Section>

            <Section className="bg-gray-50">
                <div className=" space-y-6">
                    <h1 className="text-4xl bold text-center">How We Started</h1>
                    <div className="flex justify-center">
                        <p className="text-lg w-7/12">We began our journey to build a best-in-breed AI-powered virtual assistant in 2018. Since then, the team has   grown over the years, but remained true to our intent to stay small and agile in order to quickly and efficiently respond to client needs. We built Coeus using the highly acclaimed IBM AI technology stack as the foundation. From that base, we’ve developed a solution that is easy to customize, quick to stand up, secure and private, and is simple for your business users to maintain and expand.</p>
                    </div>

                </div>
            </Section>

            <Section>
                <h1 className="text-4xl bold text-center pb-4">3 Reasons to Choose Coeus</h1>
                <div className="grid grid-cols-1 xl:grid-cols-3 gap-6">
                    <div className="rounded border p-4">
                        <h1 className="text-2xl bold text-coeusRed">01</h1>
                        <h1 className="text-2xl bold pb-4">Commitment to Excellence</h1>
                        <p>Our Coeus development team is fully committed to making Coeus the #1 go-to AI virtual assistant on the market. We stay on top of current trends and update our products regularly.</p>
                    </div>
                    <div className="rounded border p-4">
                        <h1 className="text-2xl bold text-coeusRed">02</h1>
                        <h1 className="text-2xl bold pb-4">Client-Centered</h1>
                        <p>Our promise to you is to always keep our customers at the forefront of our design. We research trends in your industry space to enhance our product to best meet your needs. We listen to your feedback.</p>
                    </div>
                    <div className="rounded border p-4">
                        <h1 className="text-2xl bold text-coeusRed">03</h1>
                        <h1 className="text-2xl bold pb-4">Industry Expertise</h1>
                        <p>Our full Coeus team has many years of experience across multiple industries. We have consultants from both operational and IT sides of the business. This expertise allows us to understand your unique challenges.</p>
                    </div>
                </div>
            </Section>

            <Section className="bg-gray-50">
                <p className="text-xl text-center">We would love for you to browse the website, request a demo, or simply contact us to show your interest in our product.</p>
            </Section>
            <CTADemo />
        </Layout>
    );
};

export default AboutUs;

export const Head = () => {
    return (
        <SEO
            title="About Us"
            description="Coeus is a customer service platform that utilizes Conversational AI to provide a unique, high-quality customer experience."
        ></SEO>
    );
};